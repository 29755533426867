import { createStore } from 'vuex'

export default createStore({
  state: {
    url: '',
    log: '',
    chosenday: '',
    chosenmonth: '',
    loads: {},
    Role: null,
    bets: null,
  },
  mutations: {
    setUrl(state, url) {
      state.url = url
    },
    setLog(state, log) {
      state.log = log
    },
    setChosenMonth(state, m) {
      state.chosenmonth = m
    },
    setChosenDay(state, d) {
      state.chosenday = d
    },
    toggleLoad(state, name) {
      if (state.loads[name])
        delete state.loads[name]
      else
        state.loads[name] = true
    },
    setRole(state, Role) {
      state.Role = Role
    },
    setBets(state, bets) {
      state.bets = bets
    },
  },
  actions: {
    reset({commit}){
      commit('setRole', false)
    },
    async checkAuth({state,commit}){
      commit('toggleLoad','checkAuth')
      await fetch(state.url + 'auth')
        .then(res=>{
          if (!res.ok) return commit('setRole', false)
          return res.text()
        }).then(role=>{
          commit('setRole', role || false)
        }).catch(err=>{
          alert(err)
          commit('setRole', false)
        })
      commit('toggleLoad','checkAuth')
    },
    async getBets({state,commit}) {
      commit('toggleLoad','getBets')
      await fetch(state.url + `bets`)
        .then(async res=>{
          if (!res.ok) throw(await res.text())
          return res.json()
        })
        .then(bets=>{ 
          for (let day of bets) {
            let split = day.date.split('.')
            let date = new Date(parseInt(split[2]), parseInt(split[1]) - 1, parseInt(split[0]))
            let ofweek
            switch (date.getDay()) {
              case 0:
                ofweek = 'вс'
                break
              case 1:
                ofweek = 'пн'
                break
              case 2:
                ofweek = 'вт'
                break
              case 3:
                ofweek = 'ср'
                break
              case 4:
                ofweek = 'чт'
                break
              case 5:
                ofweek = 'пт'
                break
              case 6:
                ofweek = 'сб'
                break
              default:
                ofweek = 'ОШИБКА'
            }
            day.ofweek = ofweek
          }
          commit('setBets', bets)
        })
        .catch(err=>{console.error(err); alert(err)})
      commit('toggleLoad','getBets')
    },
    async makeBet({state,commit,dispatch}, bet) {
      commit('toggleLoad','makeBet')
      await fetch(state.url + `bet`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bet)
      }).then(async res=>{
          console.log('here')
          if (!res.ok) throw(await res.text())
          console.log('ok')
          return dispatch('getBets')
        })
        .catch(err=>{console.error(err); alert(err)})
      commit('toggleLoad','makeBet')
    },
  },
  getters: {
    loading(state) {
      return Object.keys(state.loads).length > 0
    },
    logs(state){
      if (!state.bets) return null
      let logs = {}
      for (let day of state.bets) {
        for (let bet of day.table) {
          if (bet.login == 'root') continue
          if (!logs[bet.login]) logs[bet.login] = 0
        }
      }
      logs = Object.keys(logs)
      return logs
    },
    betsObj(state, getters){
      if (!state.bets) return null
      let logs = getters.logs, obj = []
      for (let day of state.bets) {
        let newday = { date: day.date, ofweek: day.ofweek, table: {}}
        for (let l of logs) newday.table[l] = '-'
        for (let bet of day.table) {
          if (bet.login == 'root') newday.result = bet.bet
          else newday.table[bet.login] = bet
        }
        if (!newday.result) newday.result = '?'
        obj.push(newday)
      }
      return obj
    },
    filteredBetsObj(state, getters){
      if (!getters.betsObj || !getters.betsObj.length) return false
      if (!state.chosenmonth && !state.chosenday) return getters.betsObj
      let res = []
      for (let day of getters.betsObj) {
        if (state.chosenmonth && (day.date.replace(/^.../,'') !== state.chosenmonth))
          continue
        if (state.chosenday && (day.ofweek !== state.chosenday))  
          continue
        res.push(day)
      }
      return res
    },
    filteredBets(state) {
      if (!state.bets || !state.bets.length) return false
      if (!state.chosenmonth && !state.chosenday) return state.bets
      let res = []
      for (let day of state.bets) {
          if (state.chosenmonth && (day.date.replace(/^.../,'') !== state.chosenmonth))
            continue
          if (state.chosenday && (day.ofweek !== state.chosenday))  
            continue
          res.push(day)
        }
      return res
    },
    mostAccurate(state, getters) {
      let obj = getters.filteredBetsObj

      if (!obj) return null
      let res = []
      for (let day of obj) {
        let dr = parseInt(day.result)
        if (dr == '?') continue
        for (let log in day.table) {
          if (day.table[log] == '-') continue
          res.push({date: day.date.replace(/.....$/,''), log, value: Math.abs(dr-day.table[log].bet)})          
        }
      }
      res.sort((a,b)=>a.value - b.value)
      res = res.splice(0, 10)
      return res
    },
    winnersInRow(state, getters) {
      if (!getters.filteredBets) return null
      let logs = {}
      for (let day of getters.filteredBets) {
        for (let bet of day.table) {
          if (bet.login == 'root') continue
          if (!logs[bet.login]) logs[bet.login] = [0]
          if (bet.like) logs[bet.login][logs[bet.login].length - 1]++
          else logs[bet.login].push(0)
        }
      }
      let res = []
      for (let log in logs)
        res.push({login: log, series: Math.max(...logs[log])})
      res.sort((a,b)=>b.series - a.series)
      return res
    },
    winners(state, getters) {
      if (!getters.filteredBets) return null
      let logs = {}
      for (let day of getters.filteredBets) {
        for (let bet of day.table) {
          if (bet.login == 'root') continue
          if (!logs[bet.login]) logs[bet.login] = 0
          if (bet.like) logs[bet.login]++
        }
      }
      let res = []
      for (let log in logs)
        res.push({login: log, stars: logs[log]})
      res.sort((a,b)=>b.stars - a.stars)
      return res
    },
    losers(state, getters){
      if (!getters.filteredBets) return null
      let logs = {}
      for (let day of getters.filteredBets) {
        for (let bet of day.table) {
          if (bet.login == 'root') continue
          if (!logs[bet.login]) logs[bet.login] = 0
          if (bet.dislike) logs[bet.login]++
        }
      }
      let res = []
      for (let log in logs)
        res.push({login: log, dislikes: logs[log]})
      res.sort((a,b)=>b.dislikes - a.dislikes)
      return res
    }
  }
})
