<template>
  <div class="timechoser">
    <label class="main__month" v-if="months && months.length">
      <span>Выбрать месяц</span>
      <select :value="chosenmonth" @input="$store.commit('setChosenMonth', $event.target.value)">
      <option v-for="m in months" :key="m" :value="m">{{ m }}</option>
      </select>
    </label>
    <label class="main__month" v-if="months && months.length">
      <span>Выбрать день недели</span>
      <select :value="chosenday" @input="$store.commit('setChosenDay', $event.target.value)">
      <option v-for="d in ['','пн','вт','ср','чт','пт','сб','вс']" :key="d" :value="d">{{ d }}</option>
      </select>
    </label>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

  export default {
    computed: {
      ...mapState(['chosenmonth', 'chosenday']),
      ...mapGetters(['betsObj']),
      months(){
        if (!this.betsObj || !this.betsObj.length) return []
        let obj = {}
        for (let day of this.betsObj) {
          let truncdate = day.date.replace(/^.../,'')
          obj[truncdate] = true
        }
        return ['',...Object.keys(obj)]
      }
    }
  }
</script>

<style lang="sass">
.timechoser
  display: flex
  gap: 20px
  margin-bottom: 10px
</style>